





























































import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import AuthContainer from '@/components/AuthContainer.vue';
import {MerchantAPI, ErrorAPI} from '@/api';

@Component({
  components: {
    AuthContainer
  },
})
export default class Registration extends Vue {
  private isLoading: boolean = false;
  private errors = new ErrorAPI();

  private name: string | null = null;
  private email: string | null = null;
  private password: string | null = null;
  private passwordConfirmation: string | null = null;
  private phone: string | null = null;

  validateEmail() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(this.email === null ? '' : this.email)) {
      this.errors.push('email', [this.$t('EmailValidateMsg').toString()]);
    } else {
      this.errors.clear();
    }
  }

  private register() {
    this.errors = new ErrorAPI();

    if (!this.validate()) {
      return false;
    }

    this.isLoading = true;
    MerchantAPI.register({
      name: this.name,
      email: this.email,
      password: this.password,
      password_confirmation: this.passwordConfirmation,
      phone: this.phone?.replace(/[^\d]/g, '').substring(1, 11),
    }).then((res: object) => {
      // @ts-ignore
      this.$store.dispatch('auth/fetched', {user: res.user, accessToken: res.api_token});
      this.$router.push('/dashboard');
    }).catch(ErrorAPI.parse(this.errors))
      .finally(() => {
        this.isLoading = false;
      });
  }

  private validate() {
    const form = this.$refs['vuejs-form-data'] as HTMLFormElement;
    if (form.checkValidity && !form.checkValidity()) {
      const elements = [...form.elements] as HTMLElement[];
      elements.forEach((e) => e.focus());
      // form.reportValidity();
      return false;
    }

    return true;
  }
}
